const s3 = {
  REGION: "us-east-2",
  BUCKET: "icitizen-user-images"
}
let cognito = {
  REGION: "us-east-2",
  USER_POOL_ID: "us-east-2_ocLBExOhQ",
  APP_CLIENT_ID: "2lmd5kekh6pqglh01vm8eq32q7",
  IDENTITY_POOL_ID: "us-east-2:5f62effe-ba28-4147-8464-ca2085616de0"
}
let gateway = {
  REGION: "us-east-2"
}

const hostname = window.location.hostname;

const stage = 
  hostname === 'icitizen.com' ? 'prod' :
  hostname === 'staging.icitizen.com' ? 'dev' :
  'beta';

if (stage === 'prod') {
  cognito.OAUTH = {
    REDIRECT_SIGN_IN: 'https://icitizen.com/home',
    REDIRECT_SIGN_OUT: 'https://icitizen.com/login'
  };
  gateway.URL_USERS = "https://cxzq6gqlmg.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_ARTICLES = "https://gxzxkxjar1.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_DONATIONS = "https://5sfok6e9ud.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_COMMS = "https://a8g36xy0f0.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_QUESTIONS = "https://yc76u5qfkk.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_COMMENTS = "https://lltfplbs23.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_POLLS = "https://yh901iugjb.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_EMAIL = "https://xpvcvpc8of.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_SWEEPS = "https://t6reds7707.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_AAC = "https://8zewpnszq9.execute-api.us-east-2.amazonaws.com/dev/";
} else if (stage === 'dev') {
  cognito.OAUTH = {
    REDIRECT_SIGN_IN: 'https://staging.icitizen.com/home',
    REDIRECT_SIGN_OUT: 'https://staging.icitizen.com/login'
  };
  gateway.URL_USERS = "https://kfvauij67i.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_ARTICLES = "https://cmlod99l58.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_DONATIONS = "https://ifdmwmlf98.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_COMMS = "https://sibowwevg4.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_QUESTIONS = "https://bvqy5ly0r0.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_COMMENTS = "https://9hdqh8mqv1.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_POLLS = "https://mg329le2o3.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_EMAIL = "https://02glx3sq50.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_SWEEPS = "https://t6reds7707.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_AAC = "https://8zewpnszq9.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_GETCHATRESPONSE = "https://4kykixjejwjkne3i2zunhhigvi0lommj.lambda-url.us-east-2.on.aws/";
  gateway.URL_GETGREETING = "https://i3iom43qe6a32ksapu5ybc2nxa0xzcig.lambda-url.us-east-2.on.aws/";
} else {
  cognito.OAUTH = {
    REDIRECT_SIGN_IN: 'https://localhost:3000/home',
    REDIRECT_SIGN_OUT: 'https://localhost:3000/login'
  };
  gateway.URL_USERS = "https://kfvauij67i.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_ARTICLES = "https://cmlod99l58.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_DONATIONS = "https://ifdmwmlf98.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_COMMS = "https://sibowwevg4.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_QUESTIONS = "https://bvqy5ly0r0.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_COMMENTS = "https://9hdqh8mqv1.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_POLLS = "https://mg329le2o3.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_EMAIL = "https://02glx3sq50.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_SWEEPS = "https://t6reds7707.execute-api.us-east-2.amazonaws.com/prod/";
  gateway.URL_AAC = "https://8zewpnszq9.execute-api.us-east-2.amazonaws.com/dev/";
  gateway.URL_GETCHATRESPONSE = "https://4kykixjejwjkne3i2zunhhigvi0lommj.lambda-url.us-east-2.on.aws/";
  gateway.URL_GETGREETING = "https://i3iom43qe6a32ksapu5ybc2nxa0xzcig.lambda-url.us-east-2.on.aws/";
}

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 2000000,
  s3: s3,
  cognito: cognito,
  apiGateway: gateway
};

export default config;
